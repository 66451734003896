<template>
  <div class="logincontainer">
    <div class="toggle">
      <div class="telphonetitle itemtitle " @click="changePCMode('telphone')">密码登录</div>
      <div class="msg	itemtitle currenttitle">验证码登录</div>
    </div>
    <div class="action">
      <el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm">

        <el-form-item class="inputitem" prop="telphone" :rules="rules.telphone">
          <el-input placeholder="请输入手机号码" prefix-icon="el-icon-user" v-model="ruleForm.telphone"></el-input>
        </el-form-item>
        <el-form-item prop="code" class="inputitem" :rules="rules.code">
          <el-input placeholder="短信验证码" type="code" prefix-icon="el-icon-key" v-model="ruleForm.code">
            <i slot="suffix">
              <div class="inputslot">
                <div class='line'></div>
                <div class="msgBtn" v-if="timer===0" @click.prevent="getMsgCode">获取验证码</div>
                <div class="msgBtn" v-else> {{timer}}秒内输入</div>
              </div>

            </i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submitbtn" type="primary" @click="submitForm('ruleForm')">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getTelphoneMsgCode, TelphoneMsgCodeLogin } from "@/api/login.js";
export default {
  name: "FrontendPcBigdataTelphone",

  data() {
    return {
      timer: 0,
      ruleForm: {
        telphone: "",
        password: "",
      },
      rules: {
        telphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //验证手机号
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            min: 4,
            max: 4,
            message: "验证码格式不正确",
            traggers: "blur",
          },
        ],
      },
    };
  },

  computed: {
    membersId: {
      get() {
        return this.$store.state.membersId
      },
      set(v) {
        this.$store.commit('setMembersId', v);
      }
    },
    loginMode: {
      get() {
        return this.$store.state.loginMode;
      },
      set(v) {
        this.$store.commit("setLoginMode", v);
      },
    },
  },
  methods: {
    //获取验证码
    getMsgCode() {
      //验证手机号码格式
      var that = this;
      this.$refs.ruleForm.validateField("telphone", function (valid) {
        if (!valid) {
          const params = {
            phone: that.ruleForm.telphone,
          };
          getTelphoneMsgCode(params).then((res) => {
            //发送成功后的处理
            that.timer = 120;
            const timerAction = setInterval(function () {
              if (that.timer > 0) {
                that.timer--;
              } else {
                clearInterval(timerAction);
              }
            }, 1000);
          });
        }
      });
    },

    changePCMode(v) {
      this.loginMode = v;
    },

    submitForm() {
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          const params = {
            login_type: "code", //密码登录
            phone: that.ruleForm.telphone,
            code: that.ruleForm.code,
          };
          TelphoneMsgCodeLogin(params).then((res) => {

            if (res.data.profile) {

              //登录成功
              const token = res.data.token;
              that.$store.commit('setToken', token)
         
              that.$router.push('/')
            } else {
              //完善信息获取要完善信息的id
              that.membersId = res.data.members_id
   
              that.$router.push('/bindmemberinfo')
            }

          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.submitbtn {
  width: 300px;
  height: 40px;
  background: #28a7e1;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.inputitem {
  width: 290px;
  border-bottom: solid 1px #f2f2f2;

  ::v-deep .el-input__inner {
    border: none;
  }
}

.logincontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle {
  padding-top: 10px;
  display: flex;
  width: 290px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .itemtitle {
    box-sizing: border-box;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 32px;
  }




  .currenttitle {
    color: #28a7e1;
    border-bottom: solid 2px #28a7e1;
  }
}

.inputslot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;

  .msgBtn {
    width: 80px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28a7e1;
    cursor: pointer;
  }

  .line {
    height: 14px;
    width: 1px;
    background: #c5c5c5;
  }
}
</style>