<template>
  <div class="logincontainer">
    <div class="toggle">
      <div class="telphonetitle itemtitle currenttitle">密码登录</div>
      <div
        class="msg	itemtitle"
        @click="changePCMode('msg')"
      >验证码登录</div>
    </div>
    <div class="action">
      <el-form
        class="form"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item
          class="inputitem"
          prop="telphone"
          :rules="rules.telphone"
        >
          <el-input
            placeholder="请输入手机号码"
            prefix-icon="el-icon-user"
            v-model="ruleForm.telphone"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          class="inputitem"
          :rules="rules.password"
        >
          <el-input
            placeholder="请输入密码"
            type="password"
            prefix-icon="el-icon-key"
            v-model="ruleForm.password"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitbtn"
            type="primary"
            @click="submitForm('ruleForm')"
          >立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { passwordLogin } from "@/api/login.js";
export default {
  name: "FrontendPcBigdataTelphone",

  data() {
    return {
      ruleForm: {
        telphone: "",
        password: "",
      },
      rules: {
        telphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //验证手机号
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],

        password: [
          {
            required: true,
            message: "密码不能为空",
            traggers: "blur",
          },
        ],
      },
    };
  },

  computed: {
    loginMode: {
      get() {
        return this.$store.state.loginMode;
      },
      set(v) {
        this.$store.commit("setLoginMode", v);
      },
    },
  },
  methods: {
    changePCMode(v) {
      this.loginMode = v;
    },

    submitForm() {
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          const params = {
            login_type: "password", //密码登录
            phone: that.ruleForm.telphone,
            password: that.ruleForm.password,
          };
          passwordLogin(params).then((res) => {
         
               //登录成功
              const token = res.data.token;
              that.$store.commit('setToken',token)
            that.$router.push('/')
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.submitbtn {
  width: 300px;
  height: 40px;
  background: #28a7e1;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.inputitem {
  width: 290px;
  border-bottom: solid 1px #f2f2f2;
  ::v-deep .el-input__inner {
    border: none;
  }
}
.logincontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.toggle {
  padding-top: 10px;
  display: flex;
  width: 290px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .itemtitle {
    box-sizing: border-box;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #28a7e1;
    line-height: 32px;
  }
  .currenttitle {
    border-bottom: solid 2px #28a7e1;
  }
}
</style>