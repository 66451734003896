<template>
  <div>
		<div id="qrcode"></div>
  </div>
</template>

<script>
export default {
  name: "FrontendPcBigdataScan",

  data() {
    return {};
  },

  mounted() {
    var obj = new WxLogin({
      // self_redirect: true,
      id: "qrcode",
      appid: "wx85501993da2243b8",
      scope: "snsapi_login",
			href:"https://zhibo.chinatupai.com/wxlogin.css",
      redirect_uri: encodeURIComponent(
        "http://web.bigdata.chinatupai.com/#/scanloading"
      ),
    });
  },

  methods: {

	},
};
</script>

<style lang="scss" scoped>
</style>

