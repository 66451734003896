<template>
  <div class="logincontainer">
    <login-header></login-header>

    <div class="loginconent">
      <div class="rightbox">

        <div class="loginbox">
          <div class="logotop">
            <img
              class="logoimg"
              src="/pic/logo.png"
              alt=""
            >
          </div>
          <div class="loginform">
            <img
              class="formimg"
              src="/pic/loginboxbg.png"
              alt=""
            >
            <div class="innerBox">
              <div class="inputform">
                <telphone v-if="loginMode === 'telphone'"></telphone>
                <msg v-if="loginMode === 'msg'"></msg>
                <scan v-if="loginMode === 'scan'"></scan>
              </div>

              <div
                class="changeMode"
                @click="changeMode"
              >

                <div
                  v-if="loginMode!=='scan'"
                  class="changetext"
                >微信扫一扫登录</div>
                <img
                  v-if="loginMode!=='scan'"
                  src="/pic/weixin.png"
                >
                <div
                  v-if="loginMode ==='scan'"
                  class="changetext2"
                >
                  <div class="chtelbtn">使用手机号登录</div>
                  <img
                    class="arrowright"
                    src="/pic/arrowright.png"
                  />
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LoginHeader from "@/components/login/loginheader.vue";
import telphone from "@/components/login/telphone.vue";
import msg from "@/components/login/msg.vue";
import scan from "@/components/login/scan.vue";

export default {
  name: "FrontendPcBigdataLogin",
  components: {
    LoginHeader,
    telphone,
    msg,
    scan,
  },

  computed: {
    membersWechatScanId: {
      get() {
        return this.$store.state.membersWechatScanId;
      },
      set(v) {
        this.$store.commit("setMembersWechatScanId", v);
      },
    },

    membersId: {
      get() {
        return this.$store.state.membersId;
      },
      set(v) {
        this.$store.commit("setMembersId", v);
      },
    },
    loginMode: {
      get() {
        return this.$store.state.loginMode;
      },
      set(v) {
        this.$store.commit("setLoginMode", v);
      },
    },
  },
  data() {
    return {
      btnicon: "/pic/erwei.png",
    };
  },

  mounted() {
    this.membersWechatScanId = "";
    this.membersId = "";
  },

  methods: {
    changeMode() {
      //scan 扫码   telphone  电话号码  msg 信息验证码
      if (this.loginMode === "scan") {
        this.loginMode = "telphone";
        this.btnicon = "/pic/erwei.png";
        return;
      } else {
        this.loginMode = "scan";
        this.btnicon = "/pic/zhanghao.png";
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logincontainer {
  background: #fff;
}
.loginconent {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: 100%;
  background: #f0f3f8 url("~@/assets/loginbg1.jpg") left 13% center no-repeat;
  background-size: 40%;

  .rightbox {
    width: 50%;
    min-width: 430px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loginbox {
    width: 430px;
    .logotop {
      height: 108px;
      width: 100%;
      img {
        height: 108px;
      }
    }

    .loginform {
      width: 430px;
      height: 488px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      overflow: hidden;
      justify-content: flex-start;
      align-items: center;
      .formimg {
        width: 430px;
        height: 181px;
      }
      .innerBox {
        height: 307px;
        width: 430px;
        background: #fff;
        .inputform {
          height: 243px;
          width: 430px;
        }
        .changeMode {
          height: 88px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .changetext {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-bottom: 5px;
            color: #000000;
             cursor: pointer;
          }
          .changetext2 {
            cursor: pointer;
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #50b6e5;
            line-height: 22px;
            display: flex;    
            justify-content: center;
            align-items: center;
            z-index:10;
            .chtelbtn {
            }
            .arrowright {
              height: 16px;
              width: 16px;
            }
          }

          img {
            height: 28px;
            width: 28px;
            padding-right: 2px;
            padding-bottom: 2px;
            box-sizing: border-box;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>